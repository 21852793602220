<template>
  <v-card>
    <v-card-title>Editar Motorista</v-card-title>
    <v-card-text>
      <v-form ref="formUpdateDriver" @submit.prevent="updateDriver()">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              label="Nome"
              v-model="driver.name"
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              label="Veículo"
              :items="vehicles"
              item-text="plate"
              item-value="id"
              v-model="driver.vehicle_id"
              :loading="loadVehicles"
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" md="2">
            <v-btn
              color="secondary"
              class="ma-2"
              type="submit"
              :loading="saving"
              >Salvar</v-btn
            >
            <v-btn
              color="error"
              class="ma-2"
              @click="$emit('cancelUpdate', true)"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import http from "@/modules/http";
export default {
  mounted() {
    this.getVehicles();
    this.editedDriver = this.driver;
  },
  props: {
    driver: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loadVehicles: false,
      vehicles: [],
      editedDriver: {},
      saving: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
    };
  },
  methods: {
    getVehicles() {
      this.loadVehicles = true;
      http
        .get("api/vehicles")
        .then((res) => {
          this.loadVehicles = false;
          this.vehicles = res.data;
        })
        .catch((err) => {
          this.loadVehicles = false;
          console.log(err);
        });
    },
    updateDriver() {
      if (this.$refs.formUpdateDriver.validate()) {
        this.saving = true;
        http
          .put("api/drivers/" + this.driver.id, this.editedDriver)
          .then((res) => {
            this.$side({
              type: "success",
              msg: "Salvo com sucesso aqui",
              duration: 4000,
            });
            this.saving = false;
            this.$emit("driverUpdated", true);
            this.$refs.formUpdateDriver.reset();
          })
          .catch((err) => {
            console.log(err);
            this.saving = false;
          });
      }
    },
  },
};
</script>