<template>
  <div>
    <create-driver @driverSaved="getDrivers()" />
    <h1>Motoristas</h1>

    <v-btn
      color="secondary"
      class="mx-1 my-2"
      @click="getDrivers()"
      :loading="loading"
    >
      <v-icon> mdi-refresh </v-icon>
    </v-btn>

    <v-data-table :loading="loading" :items="drivers" :headers="headers">
      <template v-slot:[`item.vehicle_id`]="{ item }">
        <span>{{ item.vehicles.plate }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="openEditModal(item)" color="warning">
          mdi-pencil
        </v-icon>
        <v-icon
          @click="deleteDriver(item)"
          color="error"
          :disabled="!deleting ? false : true"
        >
          {{ !deleting ? "mdi-delete" : "mdi-progress-close" }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog persistent v-model="editModal">
      <edit-driver
        v-if="editModal"
        @cancelUpdate="handleEditDriver()"
        @driverUpdated="handleEditDriver()"
        :driver="clickedDriver"
      />
    </v-dialog>
  </div>
</template>

<script>
import http from "@/modules/http";
import CreateDriver from "../components/Create.vue";
import EditDriver from "../components/Edit.vue";
export default {
  components: {
    CreateDriver,
    EditDriver,
  },
  data() {
    return {
      drivers: [],
      clickedDriver: {},
      headers: [
        { text: "Nome", value: "name" },
        { text: "Veículo", value: "vehicle_id" },
        { text: "Ações", value: "actions" },
      ],
      loading: false,
      deleting: false,
      editModal: false,
    };
  },
  methods: {
    handleEditDriver() {
      this.editModal = false;
      this.getDrivers();
    },
    openEditModal(driver) {
      this.clickedDriver = driver;
      this.editModal = true;
    },
    deleteDriver(driver) {
      this.$confirm({
        type: "error",
        title: "Remover Veículo",
        content: "Tem certeza que deseja excluir este motorista?",
        confirmText: "Sim",
        cancelText: "Não",
        confirm: () => {
          this.deleting = true;
          http
            .delete("api/drivers/" + driver.id)
            .then((res) => {
              console.log(res);
              this.deleting = false;
              this.$side({
                type: "success",
                msg: "Removido com sucesso",
                duration: 4000,
              });
              this.getDrivers();
            })
            .catch((err) => {
              this.deleting = false;
              console.log(err);
            });
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
        cancel: () => {
          console.log("Your callback");
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
      });
    },
    getDrivers() {
      this.loading = true;
      http
        .get("api/drivers")
        .then((res) => {
          this.loading = false;
          this.drivers = res.data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
    this.getDrivers();
  },
};
</script>