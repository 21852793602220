<template>
  <div>
    <h1>Adicionar Motorista</h1>
    <v-form ref="formCreateDriver" @submit.prevent="save()">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            label="Nome"
            v-model="driver.name"
            :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            label="Veículo"
            :items="vehicles"
            item-text="plate"
            item-value="id"
            v-model="driver.vehicle_id"
            :loading="loadVehicles"
            :rules="requiredRules"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn color="secondary" :loading="saving" class="mt-2" type="submit"
            >Salvar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  mounted() {
    this.getVehicles();
  },
  data() {
    return {
      driver: {
        name: "",
        vehicle_id: "",
      },
      saving: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
      vehicles: [],
      loadVehicles: false,
    };
  },
  methods: {
    cleanForm() {
      this.driver = {
        name: "",
        vehicle_id: "",
      };
    },
    getVehicles() {
      this.loadVehicles = true;
      http
        .get("api/vehicles")
        .then((res) => {
          this.loadVehicles = false;
          this.vehicles = res.data;
        })
        .catch((err) => {
          this.loadVehicles = false;
          console.log(err);
        });
    },
    save() {
      if (this.$refs.formCreateDriver.validate()) {
        this.saving = true;
        http
          .post("api/drivers", this.driver)
          .then((res) => {
            this.$side({
              type: "success",
              msg: "Salvo com sucesso",
              duration: 4000,
            });
            this.cleanForm();
            this.$refs.formCreateDriver.reset();
            this.saving = false;
            this.$emit("driverSaved", true);
          })
          .catch((err) => {
            console.log(err);
            this.saving = false;
          });
      }
    },
  },
};
</script>